<template>
  <div class="list-wrapper">
    <div class="arrow-up"></div>

    <div class="list">
      <!-- H3 Notifications -->
      <div class="list-header">
        <p>H3 Notifications</p>
      </div>
      <div v-if="adminListNotifs">
        <div class="admin-list"
             v-for="(adminList, index) in adminListNotifs.slice(0, 5)"
             :key="index"
             :class="adminList.class_name"
             @click="readAdminNotif(adminList)">
          <p><strong>{{ adminList.title }}</strong></p>
          <p class="notif-msg">{{ adminList.message }}</p>
          <div class="icon-time">
            <img class="notif-img" src="../../assets/images/logo_icon.png" />
            <p class="date">{{ adminList.created | formatRelativeTime }}</p>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="no-list">No H3 Notification</p>
      </div>

      <!-- Activity Notification -->
      <div class="list-header">
        <p>Activity Notifications</p>
      </div>
      <div v-if="activityNotifs">
        <div class="activity-list"
             v-for="activityList in activityNotifs.slice(0, 5)"
             :key="activityList.activity_notif_id"
             :class="activityList.status"
             @click="readActivityNotif(activityList)">
          <p><strong>{{ activityList.title }}</strong></p>
          <p class="notif-msg">{{ activityList.message }}</p>
          <div class="icon-time">
            <img class="notif-img-2"
               src="../../assets/images/new_school_icon.png" />
            <p class="date">{{ activityList.created | formatRelativeTime }}</p>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="no-list">No Activity Notification</p>
      </div>

      <!-- View All -->
      <router-link to="/notification-all">
        <p class="view-all">View All</p>
      </router-link>
    </div>
  </div>
</template>

<script>
  const ReportedContent =
    () => import('@/components/modals/ReportedContent.vue');
  import FilterMixin from '@/mixins/FilterMixin';
  import { mapActions } from 'vuex';
  export default {
    name   : 'NotificationList',
    mixins : [
      FilterMixin,
    ],
    props : {
      show : Boolean,
    },
    data() {
      return {
        adminListNotifs : this.$store.state.notification.adminListNotifs.data,
        activityNotifs  : this.$store.state.notification.activityNotifs.data,
      }
    },
    watch : {
      '$store.state.notification.adminListNotifs'() {
        this.adminListNotifs =
          this.$store.state.notification.adminListNotifs.data;
      },
      '$store.state.notification.activityNotifs'() {
        this.activityNotifs =
          this.$store.state.notification.activityNotifs.data;
      },
      show(value) {
        if (value) {
          this.getAdminListNotifs();
          this.getActivityNotifs();
        }
      },
    },
    methods : {
      ...mapActions({
        'getAdminListNotifs' : 'notification/getAdminListNotifs',
        'getActivityNotifs'  : 'notification/getActivityNotifs',
      }),

      /**
       * Set Admin Notif as read
       * @param adminNotif
       */
      readAdminNotif(adminNotif) {
        if (adminNotif.class_name === 'unread') {
          this.$http.post('api/notification/admin/read', {
            adminNotifId : adminNotif.admin_notif_id,
          }).then(() => {
            // update admin list notifs
            this.getAdminListNotifs();
          });
        }
        this.$emit('close-notif-list');

        // redirect
        this.$router.push({ path : adminNotif.website_redirect_url });
      },

      /**
       * Set Activity Notif as read
       * @param activityNotif
       */
      readActivityNotif(activityNotif) {
        if (activityNotif.status === 'unread') {
          this.$http.post('api/notification/activity/read', {
            activityNotifId : activityNotif.activity_notif_id,
          }).then(() => {
            // update activity notifs
            this.getActivityNotifs();
          });
        }
        this.$emit('close-notif-list');
        
        if (activityNotif.notification_type === 21 ||
          activityNotif.notification_type === 22) {
          const str = activityNotif.website_redirect_url.split('/');
          const uccId = str[str.length - 1];

          this.$modal.show(ReportedContent, {
            uccId       : uccId,
            notifTypeId : activityNotif.notification_type,
          }, {
            height   : 'auto',
            adaptive : true,
          });
        } else {
          // redirect
          this.$router.push({ path : activityNotif.website_redirect_url });
        }
        
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/notification/notification-list";
</style>

